<template>
  <v-card>
    <v-card-title>Alerts</v-card-title>
    <v-card-subtitle>A list of alerts from a dash</v-card-subtitle>
    <v-list>
      <template v-for="(alert, index) in alerts">
        <v-list-item
          @click="showAlertContext(alert)"
          :key="index"
        >
          <v-list-item-content>
            <v-list-item-title>{{ alert.message }}</v-list-item-title>
            <v-list-item-subtitle>{{ alert.content }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider
          :key="index + '-div'"
          v-if="alerts.length-1 > index"
        />
      </template>
    </v-list>
  </v-card>
</template>
<script>
export default {
	name: 'DashAlerts',
	props: ['alerts'],
	methods: {
		showAlertContext(alert) {
			const contextArray = alert.context.split(':')
			switch(contextArray[0]) {
				case 'order':
					this.$store.commit('updateContextOverlay', {
						'component': 'components/order/OrderProfile',
						'props': {
							'entangleKey': contextArray[1]
						}
					})
					break
        case 'item':
          // This needs to change to use the contextOverlay for Item Profile.
          window.open(alert.path, '_blank')
          break
        case 'variation':
          window.open(alert.path, '_blank')
          break
			}
		}
	}
}
</script>
